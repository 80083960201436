var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: {
          "head-btn-options": _vm.headBtnOptions,
          "head-title":
            this.type == "edit"
              ? _vm.$t("cip.plat.reportcenter.report.title.editHeadTitle")
              : this.type == "view"
              ? _vm.$t("cip.plat.reportcenter.report.title.viewHeadTitle")
              : _vm.$t("cip.plat.reportcenter.report.title.addHeadTitle"),
        },
        on: {
          "report-view": function ($event) {
            return _vm.reportView()
          },
          "head-save": function ($event) {
            return _vm.headSave()
          },
          "head-save-cancel": function ($event) {
            return _vm.headSave(true)
          },
          "head-cancel": _vm.headCancel,
        },
      }),
      _c(
        "el-scrollbar",
        [
          _c("form-layout", {
            ref: "formLayout",
            staticClass: "form",
            attrs: { column: _vm.formColumn, dataForm: _vm.dataForm },
            on: { getFileName: _vm.getFileName },
          }),
          _vm.reportViewDialog
            ? _c("iframe", {
                ref: "iframe",
                staticStyle: { "text-align": "center" },
                attrs: {
                  src: _vm.iframeUrl,
                  id: "iframe",
                  width: "100%",
                  height: "500",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }