<template>
  <div>
    <head-layout
      :head-btn-options="headBtnOptions"
      :head-title="this.type == 'edit' ? ($t('cip.plat.reportcenter.report.title.editHeadTitle')) : (this.type == 'view' ? ($t('cip.plat.reportcenter.report.title.viewHeadTitle')) : $t('cip.plat.reportcenter.report.title.addHeadTitle'))"
      @report-view="reportView()"
      @head-save="headSave()"
      @head-save-cancel="headSave(true)"
      @head-cancel="headCancel"
    ></head-layout>
    <el-scrollbar>
      <form-layout
        class="form"
        ref="formLayout"
        :column="formColumn"
        :dataForm="dataForm"
        @getFileName="getFileName"
      ></form-layout>
      <iframe
        v-if="reportViewDialog"
        ref="iframe"
        :src="iframeUrl"
        id="iframe"
        width="100%"
        height="500"
        style="text-align: center"
      ></iframe>
    </el-scrollbar>
  </div>
</template>
<script>

let baseUrl = '/api/sinoma-system/dict-biz/dictionary?code='
import HeadLayout from "@/views/components/layout/head-layout";
import FormLayout from "@/views/components/layout/form-layout";
import {update, getDetail} from "@/api/system/report";
import {tree} from "@/api/system/reportCate"
import {mapGetters} from "vuex";

export default {
  name: "reportEdit",
  components: {
    FormLayout,
    HeadLayout
  },
  data() {
    return {
      reportFileName: '',
      reportViewDialog: false,
      type: 'view',
      dataForm: {},
      nodeData: {},
      treeData:[],
      reportTypeName: '',
      reportTypeId: null
    }
  },
  computed: {
    ...mapGetters(['language']),
    headBtnOptions() {
      let result = [];
      if (['edit', 'view'].includes(this.type)) {
        result.push(
          {
            label: this.$t('cip.plat.reportcenter.report.btn.reportView'),
            emit: "report-view",
            type: "button",
            icon: "",
            // btnOptType: 'save',
          }
        );
      }
      if (['add', 'edit'].includes(this.type)) {
        result.push(
          {
            label: this.$t('cip.cmn.btn.saveBtn'),
            emit: "head-save",
            type: "button",
            icon: "",
            // btnOptType: 'save',
          }
        );
        result.push(
          {
            // todo 保存并返回
            label: this.$t('cip.cmn.btn.saveBackBtn'),
            emit: "head-save-cancel",
            type: "button",
            icon: "",
          }
        );
      }
      result.push(
        {
          label: this.$t('cip.cmn.btn.celBtn'),
          emit: "head-cancel",
          type: "button",
          icon: "",
          btnOptType: 'cancel',
        }
      );
      return result;
    },
    formColumn() {
      let validatePass = (rule, value, callback) => {
        if (value != '') {
          let val = JSON.parse(value)
          let language = this.language
          let val2 = val[language]
          if (val2.substring(0, 1) == " " || val2.substring(val2.length - 1) == " ") {
            callback(new Error(this.$t('cip.cmn.rule.deleteWarning')))
          }
          if (val2.length > 60) {
            callback(new Error(this.$t('cip.cmn.rule.nameLength6Warning')));
          } else {
            callback();
          }
        } else {
          callback(new Error(this.$t('cip.plat.reportcenter.report.field.reportName') + this.$t('cip.cmn.rule.noEmptyWarning')));
        }
      };
      return [
        {
          label: this.$t('cip.plat.reportcenter.report.field.reportName'),
          prop: 'reportName',
          labelWidth: 130,
          span: 8,
          isGlobal: true,
          maxlength: 50,
          placeholder: this.$t(`cip.plat.reportcenter.report.field.reportName`),
          read: !['add', 'edit'].includes(this.type),
          rules: [{required: true, validator: validatePass, trigger: 'blur'}]
        },
        {
          label: this.$t('cip.plat.reportcenter.report.field.reportTypeName'),
          labelWidth: 130,
          type: 'select',
          props: {
            label: "reportCategoryName",
            value: "id"
          },
          dicData: this.treeData,
          prop: "reportTypeName",
          readonly: false,
          placeholder: this.$t(`cip.plat.reportcenter.report.field.reportTypeName`),
          rules: [{
            required: true,
            message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.reportcenter.report.field.reportTypeName'),
            trigger: "blur"
          }],
          span: 8,
          change: (value)=>{
            let values = Number(value.value)
            console.log(value.value)
            if (!values) return
            this.$refs.formLayout.dataForm.reportTypeId  = value.value
            let reportTypeName = this.treeData.find(e => e.id == value.value).reportCategoryName
            this.reportTypeName = reportTypeName
          }
        },
        {
          label: this.$t('cip.plat.reportcenter.report.field.reportType'),
          prop: 'reportType',
          labelWidth: 130,
          span: 8,
          type: 'select',
          dataType: "string",
          disabled: !['edit', 'add'].includes(this.type),
          dicUrl: `${baseUrl}REPORT_TYPE`,
          row: true,
          rules: [
            {
              required: true,
              message: this.$t(`cip.cmn.rule.selectWarning`) + this.$t('cip.plat.reportcenter.report.field.reportType'),
              trigger: "blur",
            },
          ],
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          control: (val) => {
            if (val == '1003') {
              this.$refs.formLayout.column[4].display = false;
              this.$refs.formLayout.column[5].display = false;
              this.$refs.formLayout.column[6].readonly = false;
              this.$refs.formLayout.column[7].display = true;
            } else {
              this.$refs.formLayout.column[4].display = true;
              this.$refs.formLayout.column[5].display = true;
              this.$refs.formLayout.column[6].readonly = true;
              this.$refs.formLayout.column[7].display = false;
            }
          },
        },
        {
          label: this.$t('cip.plat.reportcenter.report.field.sort'),
          prop: 'sort',
          labelWidth: 130,
          type: 'number',
          minRows: 0,
          maxRows: 1000000000,
          value: 0,
          readonly: !['add', 'edit'].includes(this.type),
          disabled: !['add', 'edit'].includes(this.type),
          placeholder: this.$t(`cip.plat.reportcenter.report.field.sort`),
          rules: [
            {
              required: true,
              message: this.$t(`cip.cmn.rule.inputWarning`) + this.$t('cip.plat.reportcenter.report.field.sort'),
              trigger: "blur",
            },
          ],
          row: true
        },
        {
          label: this.$t('cip.plat.reportcenter.report.btn.mainFilePath'),
          prop: 'filePath',
          type: 'upload',
          labelWidth: 130,
          row: true,
          dataType: 'string',
          loadText: this.$t('cip.plat.reportcenter.report.msg.loadText'),
          propsHttp: {
            url: 'link',
            name: 'originalName',
            res: 'data'
          },
          limit: 1,
          tip: this.$t('cip.plat.reportcenter.report.msg.only'),
          action: "/api/sinoma-resource/oss/endpoint/method2",
          display: true,
          readonly: !['edit', 'add'].includes(this.type),
          uploadExceed(limit, files, fileList, column) {
            this.$message.error(this.$t('cip.plat.reportcenter.report.msg.exceed'))
          },
          uploadPreview(file) {
            this.$message.error(this.$t('cip.plat.reportcenter.report.msg.typeError'));
          },
          /* uploadAfter(res, done, loading, column) {
            this.$nextTick(() => {
              console.log(this.dataForm)
              console.log(this.option)
            })
            console.log(res, column)
            this.reportFileName = res.originalName
            done()
            this.$message.success('上传后的方法')

          }*/
        },
        {
          label: this.$t('cip.plat.reportcenter.report.btn.otherFilePath'),
          prop: 'otherFilePath',
          type: 'upload',
          labelWidth: 130,
          row: true,
          dataType: 'string',
          loadText: this.$t('cip.plat.reportcenter.report.msg.loadText'),
          propsHttp: {
            url: 'link',
            name: 'originalName',
            res: 'data'
          },
          action: "/api/sinoma-resource/oss/endpoint/method2",
          display: true,
          readonly: !['edit', 'add'].includes(this.type),
          uploadPreview(file) {
            this.$message.error(this.$t('cip.plat.reportcenter.report.msg.typeError'));
          },
        },
        {
          label: this.$t('cip.plat.reportcenter.report.field.reportReviewPath'),
          prop: 'reportReviewPath',
          labelWidth: 130,
          type: 'textarea',
          maxlength: 500,
          readonly: true,
          placeholder: this.$t(`cip.plat.reportcenter.report.field.reportReviewPath`),
        },
        {
          label: this.$t('cip.plat.reportcenter.report.field.reportFillPath'),
          prop: 'reportFillPath',
          labelWidth: 130,
          type: 'textarea',
          maxlength: 500,
          placeholder: this.$t(`cip.plat.reportcenter.report.field.reportFillPath`),
        },
        {
          label: this.$t('cip.plat.reportcenter.report.field.remark'),
          labelWidth: 130,
          row: true,
          span: 16,
          minRows: 2,
          maxlength: 200,
          showWordLimit: true,
          prop: 'remark',
          type: 'textarea',
          readonly: !['add', 'edit'].includes(this.type),
          placeholder: this.$t(`cip.plat.reportcenter.report.field.remark`),
        },
      ]
    },
  },
  created() {
    tree().then(res => {
      this.treeData = res.data.data
      this.$refs.formLayout.option.column[1].dicData = res.data.data
    })
    let {id, type, node} = this.$route.query;
    this.type = type;
    this.nodeData = node;
    if ('view'.includes(this.type)) {
      this.dataForm.id = id
      this.initData()
      this.findObject(this.formColumn, "reportName").disabled = true
      this.findObject(this.formColumn, "reportName").clearable = true
    } else if ('edit'.includes(this.type)) {
      this.dataForm.id = id
      this.initData()
    } else {
      // 新增
      this.dataForm.reportTypeId = this.nodeData.id;
      this.dataForm.reportTypeName = this.nodeData.reportCategoryName;
    }
  },
  methods: {
    getFileName(name) {
      this.$refs.formLayout.dataForm.reportFileName = name
      this.$refs.formLayout.dataForm.reportReviewPath = this.iframeUrls + name
    },
    initData() {
      getDetail(this.dataForm.id).then(res => {
        const {data} = res.data;
        this.dataForm = data;
      })
    },
    reportView() {
      if (this.$refs.formLayout.dataForm.reportReviewPath == '' || this.$refs.formLayout.dataForm.reportReviewPath == undefined) {
        this.$message.error(this.$t('cip.plat.reportcenter.report.msg.report'))
        return
      }
      this.iframeUrl = this.$refs.formLayout.dataForm.reportReviewPath
      this.reportViewDialog = true
    },
    headSave(cancel = false) {
      this.$refs.formLayout.$refs.form.validate(async (valid) => {
        if (valid) {
          this.$loading();
          update({
            ...this.$refs.formLayout.dataForm,
          })
            .then(res => {
              const {msg, data} = res.data;
              this.dataForm = data;
              if (res.data.code == 200) {
                this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
              } else {
                this.$message.success(msg);
              }
              if (cancel) {
                this.$router.$avueRouter.closeTag();
                this.$router.back();
              } else {
                //刷新数据
                // Object.assign(this.dataForm, data)
                this.type = 'edit';
                this.initData();
              }
            })
            .finally(() => {
              this.$loading().close();
              this.$refs.formLayout.$refs.form.allDisabled = false;
            })
        }
      })
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
  }
}
</script>

<style lang="scss" scoped>
.form {
  ::v-deep .el-upload__tip {
    display: inline;
  }
}
</style>
